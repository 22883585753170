import React, { Component } from 'react';
import Shield from '../Shield.js';
import Menu from './Menu.js';



class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      header: null,
      IsItemLoaded: false,
      IsError: false,
      item_details: null,

      list_item_details: [],
      IsListItemDetailsLoaded: false,
      list_item_flow_details: [],

      list_item_valve_size: [],
      list_item_valve_type: [],
      
      list_item_valve_sensor: [],
      list_item_ws:0,
      list_item_cs:0,
      list_item_pm:0,
      value_percentage:[],
      valve_price_details:[],

      value_Fertigation_Tank_Board:0,
      value_Fertigation_Tank_Additional_Board:0,
      value_Fertigation_Stand:0,
      value_Fertigation_Pump:0,
      value_Fertigation_Valves:0,
      value_NRV:0,
      value_Float:0,

      price: 0
    }
  }

  onUpdatePrice = (val, op) => {
    this.setState({
      price: this.state.price + (op * val)
    })
  }
  onUpdateItemDetails = (val) => {
    var data = this.state.item_details

    var isNew = true;
    for (var i in data) {
      if (data[i].item_id === val.item_id) {
        data[i].qty_required = val.qty_required;
        data[i].valvle_size = val.valvle_size;
        data[i].valvle_size_id = val.valvle_size_id;
        data[i].price = val.price;
        data[i].item = val.item;
        isNew = false;
        data[i].valve_type_id = val.valve_type_id;
        data[i].valve_type_name = val.valve_type_name;
        data[i].valve_grouping = val.valve_grouping;
        data[i].valve_sensor_name = val.valve_sensor_name;
        data[i].valve_sensor_id = val.valve_sensor_id
        data[i].ws_flows = val.ws_flows;
        data[i].pm_required = val.pm_required;
        data[i].ws_backwash = val.ws_backwash;
      }
    }
    if (isNew) {
      data.push(val)
    }
    this.setState({
      item_details: data,
      IsItemLoaded: true
    })
  };


  componentDidMount() {
    this.setState({
      item_details: [],
      IsItemLoaded: true
    });

    let header = new Headers({
      'Content-Type': 'application/json'
    });

    let sentData = {
      method: 'GET',
      header: header
    };

    fetch(process.env.REACT_APP_URL + "/calculator/listitem", sentData)
      .then(res => res.json())
      .then(details => {
        this.constructData(details)

      },
        (error) => { this.setState({ IsError: true }) });
  }

  constructData(details) {
    var data = details.item_price_details;
    this.setState({
      valve_price_details:details.valve_price_details
    });
    this.setState({
      value_percentage:details.value_percentage
    })
    var tempPrice = 0;
    var tempItems = [];
    var tempFlows = [];
    var tempBack = [];
    var tempValveSize = [];
    var tempValveType = [];
    
    var tempValveSensor = [];

    for (var i in data) {
      if (data[i]["item_type"] === "CO") {
        if (data[i]["item_disable"] === 1) {
          tempPrice = tempPrice + data[i]["item_price"]
        }
        tempItems.push(data[i]);
      }
      else if (data[i]["item_type"] === "FL") {
        tempFlows.push(data[i]);
      }
      else if (data[i]["item_type"] === "BW") {
        tempBack.push(data[i]);
      }
      else if (data[i]["item_type"] === "VI") {
        tempValveSize.push(data[i]);
      }
      else if (data[i]["item_type"] === "VT") {
        tempValveType.push(data[i]);
      }
      
      else if (data[i]["item_type"] === "VS") {
        tempValveSensor.push(data[i]);
      }
      else if (data[i]["item_type"] === "WS") {
        this.setState({
          list_item_ws:data[i]
        })
      }
      else if (data[i]["item_type"] === "VB") { //Valve Board
        this.setState({
          list_item_cs:data[i]
        })
      }
      else if (data[i]["item_type"] === "PM") { //Pressure Monitoring
        this.setState({
          list_item_pm:data[i]
        })
      }


      else if (data[i]["item_type"] === "N1") { 
        this.setState({
          value_Fertigation_Tank_Board:data[i].item_price
        })
      }
      else if (data[i]["item_type"] === "N2") { 
        this.setState({
          value_Fertigation_Tank_Additional_Board:data[i].item_price
        })
      }
      else if (data[i]["item_type"] === "N3") {
        this.setState({
          value_Fertigation_Stand:data[i].item_price
        })
      }
      else if (data[i]["item_type"] === "N4") {
        this.setState({
          value_Fertigation_Pump:data[i].item_price
        })
      }
      else if (data[i]["item_type"] === "N5") {
        this.setState({
          value_Fertigation_Valves:data[i].item_price
        })
      }
      else if (data[i]["item_type"] === "N6") {
        this.setState({
          value_NRV:data[i].item_price
        })
      }
      else if (data[i]["item_type"] === "N7") { 
        this.setState({
          value_Float:data[i].item_price
        })
      }
      
   
    }

    this.setState({
      list_item_details: tempItems,
      list_item_flow_details: tempFlows,
      list_item_backwash_details: tempBack,

      list_item_valve_size: tempValveSize,
      list_item_valve_type: tempValveType,
     
      list_item_valve_sensor: tempValveSensor,

      IsListItemDetailsLoaded: true,
      price: tempPrice
    })

  }

  render() {
    var { IsError, IsItemLoaded, IsListItemDetailsLoaded } = this.state;
    if (!IsItemLoaded || !IsListItemDetailsLoaded) {
      return (
        <div className="main">
          <div className="content">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
          </div>
        </div>
      );
    }
    if (IsError) {
      return (
        <b>Something Went Wrong</b>
      )
    }
    //console.log(this.state.user_details);
    return (

      <Shield>
        <Menu

          item_details={this.state.item_details}
          IsItemLoaded={this.state.IsItemLoaded}
          onUpdateItemDetails={this.onUpdateItemDetails}

          list_item_details={this.state.list_item_details}
          list_item_flow_details={this.state.list_item_flow_details}
          list_item_backwash_details={this.state.list_item_backwash_details}

          list_item_valve_size={this.state.list_item_valve_size}
          list_item_valve_type={this.state.list_item_valve_type}
         
          list_item_valve_sensor={this.state.list_item_valve_sensor}
          list_item_ws={this.state.list_item_ws}
          list_item_cs={this.state.list_item_cs}
          list_item_pm={this.state.list_item_pm}

          valve_price_details={this.state.valve_price_details}
          value_percentage={this.state.value_percentage}

          value_Fertigation_Tank_Board={this.state.value_Fertigation_Tank_Board}
          value_Fertigation_Tank_Additional_Board={this.state.value_Fertigation_Tank_Additional_Board}
          value_Fertigation_Stand={this.state.value_Fertigation_Stand}
          value_Fertigation_Pump={this.state.value_Fertigation_Pump}
          value_Fertigation_Valves={this.state.value_Fertigation_Valves}
          value_NRV={this.state.value_NRV}
          value_Float={this.state.value_Float}

          price={this.state.price}
          onUpdatePrice={this.onUpdatePrice}

        />

      </Shield>

    );
  }
}
export default (Home)
