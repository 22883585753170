import * as React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ToastMsg from './ToastMsg.js'
const useStyles = theme => ({
    outer: {
        display: 'table',
        position: 'relative',
    },

    innerRow: {
        display: 'table-row',
        position: 'relative',
        verticalAlign: 'middle',
    },
    innerCol: {
        display: 'table-cell',
        position: 'relative',
        verticalAlign: 'middle',
    },
    innerTextRole: {
        display: 'table-row',
        position: 'relative',
        verticalAlign: 'middle',
        minWidth: 10,
    },

    buttonProgress: {
        color: '#417505',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonroot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 25,
    },

    wrapper: {
        position: 'relative',
    },
    formControl: {
        margin: theme.spacing(0),
        //minWidth: 120,
        width: "100%"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#417505'
        }
    },
});


//enableRipple(true);
class ItemManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            primary: '#ff0000',
            errorName: false,
            qty_required: '',

            isLoadingButton: false,
            isUploadSuccess: false,
            openToast: false,
            messageToast: "",
            messageTypeToast: "success",

            valve_size_id: '',
            item: '',
            valve_type_id: '',
            valve_grouping: '',
            valve_sensor_id: '',
            ws_flows: [],
            pm_required: [],
            ws_backwash: []
        }
    }

    handleChangeValveInch = event => {
        this.setState({
            valve_size_id: event.target.value
        })
    };

    handleChangeValvetype = event => {
        this.setState({
            valve_type_id: event.target.value
        })

    };

    handleChangeValvegrouping = event => {
        this.setState({
            valve_grouping: event.target.value
        })

    };

    handleChangeValvesensor = event => {
        this.setState({
            valve_sensor_id: event.target.value
        })

    };

    handleChangeWSFlow1 = (i, event) => {
        let ws_flows = [...this.state.ws_flows];
        ws_flows[i] = event.target.value;
        this.setState({ ws_flows });
    };

    handleChangeWSBackwash = (i, event) => {
        let ws_backwash = [...this.state.ws_backwash];
        ws_backwash[i] = event.target.value;
        this.setState({ ws_backwash });
    };

    handleChangeWS_PM = (i, event) => {
        let pm_required = [...this.state.pm_required];
        pm_required[i] = event.target.checked;
        this.setState({ pm_required });
    };







    handleChangeItem = event => {
        this.setState({
            item: event.target.value,
            qty_required: ''
        })

        if (event.target.value === "sv") {
            document.getElementById("divSmartValve").style.display = "block";
            document.getElementById("divWaterSense").style.display = "none";
            document.getElementById("divNutriSense").style.display = "none";

        }
        else if (event.target.value === "ws") {
            document.getElementById("divSmartValve").style.display = "none";
            document.getElementById("divWaterSense").style.display = "block";
            document.getElementById("divNutriSense").style.display = "none";
        }
        else if (event.target.value === "ns") {
            document.getElementById("divSmartValve").style.display = "none";
            document.getElementById("divWaterSense").style.display = "none";
            document.getElementById("divNutriSense").style.display = "block";
        }
    };

    onUpdateOpenToast = () => {
        this.setState({
            openToast: false,
            messageToast: ""
        })
    };

    componentDidMount() {
        if (this.props.item_id) {
            this.constructFields();
        }

    }

    constructFields() {
        console.log(1)
        for (var i in this.props.item_details) {
            if (this.props.item_details[i].item_id === this.props.item_id) {
                var qty_required = this.props.item_details[i].qty_required;
                if (qty_required === null) { qty_required = ''; }
                this.setState({
                    qty_required: qty_required,
                    valve_size_id: this.props.item_details[i].valve_size_id,
                    item: this.props.item_details[i].item,
                    valve_type_id: this.props.item_details[i].valve_type_id,
                    valve_grouping: this.props.item_details[i].valve_grouping,
                    valve_sensor_id: this.props.item_details[i].valve_sensor_id,
                });

                this.setState({
                    ws_flows: this.props.item_details[i].ws_flows,
                    pm_required: this.props.item_details[i].pm_required,
                    ws_backwash: this.props.item_details[i].ws_backwash,
                })


                if (this.props.item_details[i].item === "sv") {
                    document.getElementById("divSmartValve").style.display = "block";
                    document.getElementById("divWaterSense").style.display = "none";
                    document.getElementById("divNutriSense").style.display = "none";

                }
                else if (this.props.item_details[i].item === "ws") {
                    document.getElementById("divSmartValve").style.display = "none";
                    document.getElementById("divWaterSense").style.display = "block";
                    document.getElementById("divNutriSense").style.display = "none";
                }
                else if (this.props.item_details[i].item === "ns") {
                    document.getElementById("divSmartValve").style.display = "none";
                    document.getElementById("divWaterSense").style.display = "none";
                    document.getElementById("divNutriSense").style.display = "block";
                }

                break;
            }
        }
    }
    postSensorCategory(data, data1) {
        this.setState({
            isLoadingButton: false,
            isUploadSuccess: true,
            //openToast: true,
            //messageToast:data.message,
            //messageTypeToast:"success"

        })

        //alert(data.message)
        this.fetchSensorCategoryDetails(data);
    }


    fetchSensorCategoryDetails(data) {
        this.props.onUpdateItemDetails(data);
        this.props.handleClose();
    }


    handleRequiredQtyChange = (event) => {
        if (event.target.value > 999) {
            return false;
        }
        this.setState({
            qty_required: event.target.value,
        });
    };

    handleRequiredQtyChangeWS = (event) => {
        if (event.target.value >= 10) {
            return false;
        }
        var t1 = [];
        var t2 = [];
        var t3 = []
        for (var i = 0; i < event.target.value; i++) {
            t1.push("");
            t2.push(false);
            t3.push("");
        }
        this.setState({
            ws_flows: t1,
            pm_required: t2,
            ws_backwash: t3
        })
        this.setState({
            qty_required: event.target.value,
        });
    };

    handleRequiredQtyChangeNS = (event) => {
        if (event.target.value >= 100) {
            return false;
        }
        this.setState({
            qty_required: event.target.value,
        });
    };


    handleSubmitClickD = (event) => {
        this.handleSubmitClick();
    };


    getValveName(valve_id) {
        //console.log(this.props.list_item_valve_size);
        for (var i in this.props.list_item_valve_size) {
            if (this.props.list_item_valve_size[i].item_id === valve_id) {
                return this.props.list_item_valve_size[i]["item_name"];
            }
        }
        return "";
    }

    getValveTypeName(valve_type_id) {
        console.log(this.props.list_item_valve_type);
        for (var i in this.props.list_item_valve_type) {
            if (this.props.list_item_valve_type[i].item_id === valve_type_id) {
                return this.props.list_item_valve_type[i]["item_name"];
            }
        }
        return "";
    }

    getValveSensorName(valve_sensor_id) {
        console.log(this.props.list_item_valve_sensor);
        for (var i in this.props.list_item_valve_sensor) {
            if (this.props.list_item_valve_sensor[i].item_id === valve_sensor_id) {
                return this.props.list_item_valve_sensor[i]["item_name"];
            }
        }
        return "";
    }

    getPrice(valve_id, valve_type_id, qty_required, valve_grouping, valve_sensor_id, item) {
        var sensor_price = 0;
        var cs_board_price = this.props.list_item_cs.item_price;

        for (var j in this.props.list_item_valve_sensor) {
            if (this.props.list_item_valve_sensor[j]["item_id"] === valve_sensor_id) {
                sensor_price = this.props.list_item_valve_sensor[j]["item_price"];
            }
        }
        console.log(sensor_price);
        console.log(this.props.valve_price_details);
        if (item === "sv") {
            for (var i in this.props.valve_price_details) {
                if (this.props.valve_price_details[i].valve_id === valve_id && this.props.valve_price_details[i].valve_type_id === valve_type_id) {
                    if (valve_grouping === 1) {
                        return (this.props.valve_price_details[i]["valve_price"] + cs_board_price + sensor_price) * qty_required;
                    }
                    else if (valve_grouping === 2) {
                        return (this.props.valve_price_details[i]["valve_price"] * 2 + cs_board_price + sensor_price * 2) * qty_required;
                    }
                    return 0
                }
            }
            return 0;
        }
        else if (item === "ws") {
            var flow_price = 0;
            for (var i in this.state.ws_flows) {
                for (var j in this.props.list_item_flow_details) {
                    if (this.state.ws_flows[i] === this.props.list_item_flow_details[j].item_id) {
                        flow_price = flow_price + this.props.list_item_flow_details[j].item_price;
                    }
                }
            }

            for (var i in this.state.ws_backwash) {
                for (var j in this.props.list_item_backwash_details) {
                    if (this.state.ws_backwash[i] === this.props.list_item_backwash_details[j].item_id) {
                        flow_price = flow_price + this.props.list_item_backwash_details[j].item_price;
                    }
                }
            }


            for (var i in this.state.pm_required) {
                if (this.state.pm_required[i] === true) {
                    flow_price = flow_price + this.props.list_item_pm.item_price;
                }
            }

            return (this.props.list_item_ws.item_price * qty_required) + flow_price;

        }
        else if(item === "ns"){
            qty_required=parseInt(qty_required);
            var n0= parseInt(qty_required);
            var n1=(qty_required*this.props.value_Float)
            var n2=((qty_required+1)*this.props.value_NRV);
            var n3=((qty_required+1)*this.props.value_Fertigation_Valves);
            var n4=(this.props.value_Fertigation_Pump);
            var n5=(this.props.value_Fertigation_Tank_Board);
console.log("qty:"+n0);
console.log("value_Float:"+n1);
console.log("value_NRV:"+n2);
console.log("value_Fertigation_Valves:"+n3);
console.log("value_Fertigation_Pump:"+n4);
console.log("value_Fertigation_Tank_Board:"+n5);

            var ns_price = n0+n1+n2+n3+n4+n5;
            var remaining_qty = qty_required-6;
            var add_board=0;
            if(remaining_qty<=8 && remaining_qty>0){
                add_board=1
            }
            else if(remaining_qty>8){
                add_board = parseInt((remaining_qty/8));
                if(remaining_qty%8>0){
                    add_board++;    
                }
            }
            console.log("add_board:"+add_board);
            
            var n6=(add_board*this.props.value_Fertigation_Tank_Additional_Board);
            var n7=(this.props.value_Fertigation_Stand*(parseInt(add_board)+1));
            console.log("add_board price:"+n6);
            console.log("value_Fertigation_Stand:"+n7);
            ns_price += n6+n7
            return ns_price;
        }
        return 0;
        //console.log(this.props.valve_price_details);

    }

    handleSubmitClick() {
        var pr = this.getPrice(this.state.valve_size_id, this.state.valve_type_id, this.state.qty_required, this.state.valve_grouping, this.state.valve_sensor_id, this.state.item)
        if (pr == 0) {
            this.setState({
                openToast: true,
                messageToast: "Product Not available",
                messageTypeToast: "error"
            })
            return;
        }
        if (this.state.qty_required === '') {

            this.setState({
                errorName: true,
            });
        } else {
            this.setState({
                isLoadingButton: true,
                isUploadSuccess: true,
            });
            if (this.props.item_id) {
                try {
                    let data = {
                        "item_id": this.props.item_id,
                        "valvle_size": this.getValveName(this.state.valve_size_id),
                        "valve_size_id": this.state.valve_size_id,
                        "price": this.getPrice(this.state.valve_size_id, this.state.valve_type_id, this.state.qty_required, this.state.valve_grouping, this.state.valve_sensor_id, this.state.item),
                        "qty_required": this.state.qty_required,
                        "item": this.state.item,
                        "valve_type_id": this.state.valve_type_id,
                        "valve_type_name": this.getValveTypeName(this.state.valve_type_id),
                        "valve_grouping": this.state.valve_grouping,
                        "valve_sensor_id": this.state.valve_sensor_id,
                        "valve_sensor_name": this.getValveSensorName(this.state.valve_sensor_id),
                        "ws_flows": this.state.ws_flows,
                        "pm_required": this.state.pm_required,
                        "ws_backwash": this.state.ws_backwash

                    }
                    this.postSensorCategory(data, 'PUT');
                } catch (error) {
                    /*this.setState({
                        openToast: true,
                        messageToast:error,
                        messageTypeToast:"error"
                    })*/
                    //alert(error);
                }
            } else {
                let data = {
                    "item_id": Math.round(new Date() / 1000),
                    "valvle_size": this.getValveName(this.state.valve_size_id),
                    "valve_size_id": this.state.valve_size_id,
                    "price": this.getPrice(this.state.valve_size_id, this.state.valve_type_id, this.state.qty_required, this.state.valve_grouping, this.state.valve_sensor_id, this.state.item),
                    "qty_required": this.state.qty_required,
                    "item": this.state.item,
                    "valve_type_id": this.state.valve_type_id,
                    "valve_type_name": this.getValveTypeName(this.state.valve_type_id),
                    "valve_grouping": this.state.valve_grouping,
                    "valve_sensor_id": this.state.valve_sensor_id,
                    "valve_sensor_name": this.getValveSensorName(this.state.valve_sensor_id),
                    "ws_flows": this.state.ws_flows,
                    "pm_required": this.state.pm_required,
                    "ws_backwash": this.state.ws_backwash

                }
                this.postSensorCategory(data, 'POST');
            }
        }
    };

    constructWaterSense(classes) {
        var t = [];
        for (var i = 0; i < this.state.qty_required; i++) {
            t.push({
                index: i,
                idBox: "flow" + i,
                idInput: "lblWaterSenseFlow" + i,
                idSelect: "selWaterSenseFlow" + i,
                //ws_flows: "",
                //pm_required:false,
                //ws_backwash:""
            });
            t[i].ws_flows = this.state.ws_flows[i]
            t[i].pm_required = this.state.pm_required[i]
            t[i].ws_backwash = this.state.ws_backwash[i]
        }


        return (
            <div>
                {t.map((value) => {
                    return (
                        <div key={"div" + value.idBox}>
                            <Box key={"box" + value.idBox} display="flex" m={1} bgcolor="background.paper" id={value.idBox}>
                                <MuiThemeProvider theme={theme}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id={value.idInput}>{"Water Sense " + (value.index + 1) + " Flow Meter"} </InputLabel>
                                        <Select
                                            labelId={value.idInput}
                                            id={value.idSelect}
                                            style={{ width: '100%' }}

                                            onChange={this.handleChangeWSFlow1.bind(this, value.index)}
                                            value={value.ws_flows || ''}
                                        >
                                            <MenuItem value={0}>Not Required</MenuItem>
                                            {this.props.list_item_flow_details.map(({ item_id, item_name }) => (
                                                <MenuItem key={"flow_id" + item_id} value={item_id}>{item_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </MuiThemeProvider>
                            </Box>
                            <Box key={"box2" + value.idBox} display="flex" m={1} bgcolor="background.paper" id={value.idBox}>
                                <MuiThemeProvider theme={theme}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id={value.idInput}>{"Water Sense " + (value.index + 1) + " Backwash"} </InputLabel>
                                        <Select
                                            labelId={value.idInput}
                                            id={value.idSelect}
                                            style={{ width: '100%' }}

                                            onChange={this.handleChangeWSBackwash.bind(this, value.index)}
                                            value={value.ws_backwash || ''}
                                        >
                                            <MenuItem value={0}>Not Required</MenuItem>

                                            {this.props.list_item_backwash_details.map(({ item_id, item_name }) => (
                                                <MenuItem key={"back_id" + item_id} value={item_id}>{item_name}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </MuiThemeProvider>
                            </Box>
                            <Box key={"boxpm" + value.idBox} display="flex" m={1} bgcolor="background.paper">
                                <MuiThemeProvider theme={theme}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={value.pm_required || false}
                                                onChange={this.handleChangeWS_PM.bind(this, value.index)}
                                                value={value.pm_required || false}
                                            />
                                        }
                                        label={"WS " + (value.index + 1) + " Pressure Monitoring"}
                                    />
                                </MuiThemeProvider>
                            </Box>
                        </div>
                    );
                })}
            </div>
        );
    }
    render() {
        const { classes } = this.props;
        const { qty_required, errorName, isLoadingButton, isUploadSuccess } = this.state;
        const buttonClassname = clsx({
            [classes.buttonSuccess]: isUploadSuccess,
        });
        return (
            <div>


                {(
                    <div style={{ widht: '100%', marginLeft: '3%', marginRight: '3%' }}>
                        <Box display="flex" m={1} bgcolor="background.paper">
                            <MuiThemeProvider theme={theme}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="lblItem">Item</InputLabel>
                                    <Select
                                        labelId="lblItem"
                                        id="selItem"
                                        style={{ width: '100%' }}
                                        value={this.state.item}
                                        onChange={this.handleChangeItem}
                                    >
                                        <MenuItem value={"sv"}>Smart Valve</MenuItem>
                                        <MenuItem value={"ws"}>Water Sense</MenuItem>
                                        <MenuItem value={"ns"}>Fertigation Unit</MenuItem>

                                    </Select>
                                </FormControl>
                            </MuiThemeProvider>
                        </Box>
                        <div id="divSmartValve" style={{ display: "none" }}>
                            <Box display="flex" m={1} bgcolor="background.paper">
                                <MuiThemeProvider theme={theme}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">Valve Size</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{ width: '100%' }}
                                            value={this.state.valve_size_id}
                                            onChange={this.handleChangeValveInch}
                                        >
                                            {this.props.list_item_valve_size.map(({ item_id, item_name }) => (
                                                <MenuItem key={"svvalve_id" + item_id} value={item_id}>{item_name}</MenuItem>
                                            ))}


                                        </Select>
                                    </FormControl>
                                </MuiThemeProvider>
                            </Box>
                            <Box display="flex" m={1} bgcolor="background.paper">
                                <MuiThemeProvider theme={theme}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="lblValveType">Valve Type</InputLabel>
                                        <Select
                                            labelId="lblValveType"
                                            id="selValveType"
                                            style={{ width: '100%' }}
                                            value={this.state.valve_type_id}
                                            onChange={this.handleChangeValvetype}
                                        >
                                            {this.props.list_item_valve_type.map(({ item_id, item_name }) => (
                                                <MenuItem key={"svtype_id" + item_id} value={item_id}>{item_name}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </MuiThemeProvider>
                            </Box>
                            <Box display="flex" m={1} bgcolor="background.paper">
                                <MuiThemeProvider theme={theme}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="lblValveGrouping">Valve Grouping</InputLabel>
                                        <Select
                                            labelId="lblValveGrouping"
                                            id="selValveGrouping"
                                            style={{ width: '100%' }}
                                            value={this.state.valve_grouping}
                                            onChange={this.handleChangeValvegrouping}
                                        >
                                            <MenuItem value={1}>Single</MenuItem>
                                            <MenuItem value={2}>Double</MenuItem>

                                        </Select>
                                    </FormControl>
                                </MuiThemeProvider>
                            </Box>
                            <Box display="flex" m={1} bgcolor="background.paper">
                                <MuiThemeProvider theme={theme}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="lblValveSensor">Sensor</InputLabel>
                                        <Select
                                            labelId="lblValveSensor"
                                            id="selValveSensor"
                                            style={{ width: '100%' }}
                                            value={this.state.valve_sensor_id}
                                            onChange={this.handleChangeValvesensor}
                                        >
                                            {this.props.list_item_valve_sensor.map(({ item_id, item_name }) => (
                                                <MenuItem key={"svsens_id" + item_id} value={item_id}>{item_name}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </MuiThemeProvider>
                            </Box>
                            <Box display="flex" m={1} bgcolor="background.paper">
                                <MuiThemeProvider theme={theme}>
                                    <TextField
                                        style={{ width: '100%' }}
                                        inputProps={{ maxLength: 3 }}
                                        label='Qty Required'
                                        type="number"
                                        onChange={this.handleRequiredQtyChange}
                                        value={qty_required} error={errorName} />
                                </MuiThemeProvider>
                            </Box>
                        </div>
                        <div id="divWaterSense" style={{ display: "none" }}>
                            <Box display="flex" m={1} bgcolor="background.paper">
                                <MuiThemeProvider theme={theme}>
                                    <TextField
                                        style={{ width: '100%' }}
                                        inputProps={{ maxLength: 1 }}
                                        label='Qty Required'
                                        type="number"
                                        onChange={this.handleRequiredQtyChangeWS}
                                        value={qty_required} error={errorName} />
                                </MuiThemeProvider>
                            </Box>
                            {
                                this.constructWaterSense(classes)
                            }

                        </div>
                        <div id="divNutriSense" style={{ display: "none" }}>
                            <Box display="flex" m={1} bgcolor="background.paper">
                                <MuiThemeProvider theme={theme}>
                                    <TextField
                                        style={{ width: '100%' }}
                                        inputProps={{ maxLength: 1 }}
                                        label='Tank Count'
                                        type="number"
                                        onChange={this.handleRequiredQtyChangeNS}
                                        value={qty_required} error={errorName} />
                                </MuiThemeProvider>
                            </Box>

                        </div>

                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" m={1} bgcolor="background.paper">
                            <div className={classes.buttonroot}>
                                <div className={classes.wrapper}>
                                    <MuiThemeProvider theme={theme}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={buttonClassname}
                                            disabled={isUploadSuccess}
                                            onClick={this.handleSubmitClickD}>Submit</Button>
                                        {isLoadingButton && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </MuiThemeProvider>
                                </div>
                            </div>


                        </Box>
                        <ToastMsg
                            openToast={this.state.openToast}
                            message={this.state.messageToast}
                            messageTypeToast={this.state.messageTypeToast}
                            onUpdateOpenToast={this.onUpdateOpenToast}
                        />
                    </div >
                )}
            </div>

        );
    }
}
export default withStyles(useStyles)(ItemManagement)