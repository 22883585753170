import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';

import PropTypes from 'prop-types';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';



import ItemList from './ItemList.js';

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    '&:focus > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};





const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    top: 'auto',
    bottom: 0,
  },

  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(0) + 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(0) + 1,
    },
  },
}));

export default function Menu(props) {
  const classes = useStyles();
  var price = 0;
  for (var i in props.item_details) {
    price += props.item_details[i]["price"];
  }

  var price60 = ((props.value_percentage[0].company_percentage / 100) * price) + price;
  var price15 = ((props.value_percentage[0].dealer_percentage / 100) * price60) + price60;

  return (
    <React.Fragment>

      <Paper square className={classes.paper}>
        <List className={classes.list}>

          <ItemList
            item_details={props.item_details}
            IsItemLoaded={props.IsItemLoaded}
            onUpdateItemDetails={props.onUpdateItemDetails}

            list_item_details={props.list_item_details}
            list_item_flow_details={props.list_item_flow_details}
            list_item_backwash_details={props.list_item_backwash_details}
            list_item_valve_size={props.list_item_valve_size}
            list_item_valve_type={props.list_item_valve_type}

            list_item_valve_sensor={props.list_item_valve_sensor}
            list_item_ws={props.list_item_ws}
            list_item_cs={props.list_item_cs}
            list_item_pm={props.list_item_pm}

            valve_price_details={props.valve_price_details}
            value_percentage={props.value_percentage}

            value_Fertigation_Tank_Board={props.value_Fertigation_Tank_Board}
            value_Fertigation_Tank_Additional_Board={props.value_Fertigation_Tank_Additional_Board}
            value_Fertigation_Stand={props.value_Fertigation_Stand}
            value_Fertigation_Pump={props.value_Fertigation_Pump}
            value_Fertigation_Valves={props.value_Fertigation_Valves}
            value_NRV={props.value_NRV}
            value_Float={props.value_Float}

            header={props.header}

            price={props.price}
            onUpdatePrice={props.onUpdatePrice}
          />

        </List>
      </Paper>


      {/*
      <Paper square className={classes.paper}>
        <List className={classes.list}>
          <ListSubheader className={classes.subheader} >Role Master</ListSubheader>
          {messages.map(({ id, primary, secondary, person }) => (
            <React.Fragment key={id}>
              <ListItem button>
                <ListItemText primary={primary} secondary={secondary} />
              </ListItem>
            </React.Fragment>   
          ))}
        </List>
      </Paper>
      <CssBaseline />*/}

      <AppBar position="fixed" color="primary" className={classes.appBar} >
        <Toolbar>
          <Typography variant="h6"><div id="lblPrice">Rs. {(props.price + price15).toFixed(2)}</div></Typography>
          {/*menuClicked ?
            (
              <Fab color="secondary" aria-label="add" className={classes.fabButton}>
                <AddIcon />
              </Fab>
            )
            : null*/}
          <div className={classes.grow} />

        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}