import React from 'react';

class Shield extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			error: false,
			info: null,
		};
	}

	componentDidCatch(error, info){
		this.setState({
			error: error,
			info: info,
		});
	}

	render(){
		if(this.state.error){
			return (<b>Something went wrong!</b>);
		}
		return this.props.children;
	}
}

export default (Shield)
