import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import AddIcon from '@material-ui/icons/Add';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';

import FullScreenDialog from './FullScreenDialog.js';


const drawerWidth = 240;
const styles = theme => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    top: 'auto',
    bottom: 0,
  },

  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    bottom: -50,
    left: "40%",
    margin: '0 auto',

  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(0) + 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(0) + 1,
    },
  },
});


class ItemList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IsLoaded: false,
      data: [],
      final_data: [],
      IsAddMode: false,
      IsEditMode: false,

      list_item_select: []
    };
  }

  onUpdateIsAddMode = (val) => {
    this.setState({
      IsAddMode: val
    })
  };
  onUpdateIsEditMode = () => {
    var final_data = this.constructData();
    for (var i in final_data) {
      this.setState({ [final_data[i].id]: false });
    }
  };

  componentDidMount() {
    var temp = []
    for (var i in this.props.list_item_details) {
      if (this.props.list_item_details[i].item_disable === 1) {
        temp.push({
          item_id: this.props.list_item_details[i].item_id,
          checked: true
        })
      }
      else if (this.props.list_item_details[i].item_disable === 0) {
        temp.push({
          item_id: this.props.list_item_details[i].item_id,
          checked: false
        })
      }

    }
    this.setState({
      list_item_select: temp
    })

    //console.log(temp)
  }

  handleClick = (id, final_data) => {
    for (var i in final_data) {
      this.setState({ [final_data[i].id]: false });
    }
    this.setState({ [id]: !this.state[id] });
  };

  handleAddClick = () => {
    this.setState({
      IsAddMode: true
    })
    document.getElementById("listingCategory").style.display = "none";
    document.getElementById("btnAddCategory").style.display = "none";

  }

  calculatePrice(price) {
    var price60 = ((this.props.value_percentage[0].company_percentage / 100) * price) + price;
    var price15 = ((this.props.value_percentage[0].dealer_percentage / 100) * price60) + price60;
    return price15.toFixed(2);// Math.round(price15,2);
  }
  constructData() {
    console.log(this.props.item_details);
    var final_data = []
    var temp = {};
    var secondary_text = '';
    for (var i in this.props.item_details) {
      temp = {}
      var valve_size = ""
      if (this.props.item_details[i].item === "sv") {//SMART VALVE BLOCK
        secondary_text = this.props.item_details[i].valvle_size + " inch";
        secondary_text += ", " + this.props.item_details[i].valve_type_name + ", " + this.props.item_details[i].valve_sensor_name;
        if (this.props.item_details[i].valve_grouping === 1) {
          secondary_text += ", Single Valve ";
        }
        else {
          secondary_text += ", Double Valve, ";
        }
        temp = {
          id: this.props.item_details[i].item_id,
          primary: this.props.item_details[i].qty_required + " Smart Valve ",
          secondary: secondary_text + " - Rs." + this.calculatePrice(this.props.item_details[i].price)
        }
      }
      else if (this.props.item_details[i].item === "ws") {
        var rs485count = 0;
        var PMcount = 0;
        for (var rs in this.props.item_details[i].ws_flows) {
          if (this.props.item_details[i].ws_flows[rs] !== 0 && this.props.item_details[i].ws_flows[rs] !== "") {
            rs485count++;
          }
        }
        for (var pm in this.props.item_details[i].pm_required) {
          if (this.props.item_details[i].pm_required[pm] === true) {
            PMcount++;
          }
        }
        secondary_text = "with " + rs485count + " RS485 valves and " + PMcount + " Pressure monitoring";
        temp = {
          id: this.props.item_details[i].item_id,
          primary: this.props.item_details[i].qty_required + " Water Sense ",
          secondary: secondary_text + " Rs. " + this.calculatePrice(this.props.item_details[i].price)
        }
      }
      else if (this.props.item_details[i].item === "ns") {
        temp = {
          id: this.props.item_details[i].item_id,
          primary: "Fertigation Units for handle " + this.props.item_details[i].qty_required + " Tank ",
          secondary: "Rs. " + this.calculatePrice(this.props.item_details[i].price)
        }
      }
      final_data.push(temp);
    }
    return final_data;
  }
  onChangeListItemCheck = (item_price, item_id, e) => {
    //console.log(item_id+":"+item_price+":"+e.target.checked)
    var temp = this.state.list_item_select;
    for (var i in this.state.list_item_select) {
      if (this.state.list_item_select[i].item_id === item_id) {
        temp[i].checked = e.target.checked
      }
    }
    //console.log(temp);
    this.setState({
      list_item_select: temp
    })
    if (e.target.checked) {
      this.props.onUpdatePrice(item_price, 1)
    }
    else {
      this.props.onUpdatePrice(item_price, -1)
    }

  };

  constructOtherItemDetails(item_id, item_name, item_price, item_disable) {
    var checked = false;
    //console.log(this.state.list_item_select);
    for (var i in this.state.list_item_select) {
      if (this.state.list_item_select[i].item_id === item_id) {
        checked = this.state.list_item_select[i].checked
      }
    }
    if (item_disable === 1) {
      //this.props.onUpdatePrice(item_price,1)
      return (<ListItem key={"list_item_" + item_id}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={true}
            disabled={true}
          />
        </ListItemIcon>
        <ListItemText primary={item_name} secondary={"Rs. " + item_price} />
      </ListItem>);
    }
    else {
      return (<ListItem key={"list_item_" + item_id}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={checked}
            onChange={this.onChangeListItemCheck.bind(this, item_price, item_id)}
          />
        </ListItemIcon>
        <ListItemText primary={item_name} secondary={"Rs. " + item_price} />
      </ListItem>);
    }

  }
  constructOtherItems() {
    //console.log(this.props.list_item_details);
    return (
      <div>
        {this.props.list_item_details.map(({ item_id, item_name, item_price, item_disable }) => (
          this.constructOtherItemDetails(item_id, item_name, item_price, item_disable)
        ))}
      </div>
    );
  }

  render() {
    if (!this.props.IsItemLoaded) {
      return (
        <div className="main">
          <div className="content">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
          </div>
        </div>
      );
    }

    var final_data = this.constructData();

    const { classes } = this.props;

    return (
      <React.Fragment>
        {!this.state.IsAddMode ?
          <Paper square className={classes.paper}>
            <List className={classes.list}>
              <Fab color="secondary" aria-label="add" className={classes.fabButton} id="btnAddCategory">
                <AddIcon onClick={this.handleAddClick} />

              </Fab>

              <div id="listingCategory">
                {final_data.map(({ id, primary, secondary, type }) => (
                  <React.Fragment key={"list_" + id}>
                    <ListItem button onClick={this.handleClick.bind(this, id, final_data)} >
                      <ListItemText primary={primary} secondary={secondary} />

                    </ListItem>
                    <Collapse key={"coll" + id} component="li" in={this.state[id]} timeout="auto" unmountOnExit>
                      <List disablePadding>
                        <FullScreenDialog
                          item_details={this.props.item_details}
                          IsItemLoaded={this.props.IsItemLoaded}

                          list_item_flow_details={this.props.list_item_flow_details}
                          list_item_backwash_details={this.props.list_item_backwash_details}
                          list_item_valve_size={this.props.list_item_valve_size}
                          list_item_valve_type={this.props.list_item_valve_type}

                          list_item_valve_sensor={this.props.list_item_valve_sensor}
                          list_item_ws={this.props.list_item_ws}
                          list_item_cs={this.props.list_item_cs}
                          list_item_pm={this.props.list_item_pm}
                          valve_price_details={this.props.valve_price_details}
                          value_percentage={this.props.value_percentage}

                          value_Fertigation_Tank_Board={this.props.value_Fertigation_Tank_Board}
                          value_Fertigation_Tank_Additional_Board={this.props.value_Fertigation_Tank_Additional_Board}
                          value_Fertigation_Stand={this.props.value_Fertigation_Stand}
                          value_Fertigation_Pump={this.props.value_Fertigation_Pump}
                          value_Fertigation_Valves={this.props.value_Fertigation_Valves}
                          value_NRV={this.props.value_NRV}
                          value_Float={this.props.value_Float}

                          header={this.props.header}

                          item_id={id}

                          onUpdateIsAddMode={this.onUpdateIsAddMode}
                          onUpdateIsEditMode={this.onUpdateIsEditMode}
                          onUpdateItemDetails={this.props.onUpdateItemDetails}

                          heading="Edit Item"
                        />
                      </List>
                    </Collapse>
                  </React.Fragment>
                ))}

                {this.constructOtherItems()}

              </div>
            </List>
          </Paper>
          :
          (
            <div>
              <FullScreenDialog
                item_details={this.props.item_details}
                IsRolIsItemLoadedeLoaded={this.props.IsItemLoaded}
                onUpdateItemDetails={this.props.onUpdateItemDetails}

                list_item_flow_details={this.props.list_item_flow_details}
                list_item_backwash_details={this.props.list_item_backwash_details}
                list_item_valve_size={this.props.list_item_valve_size}
                list_item_valve_type={this.props.list_item_valve_type}
                valve_price_details={this.props.valve_price_details}
                value_percentage={this.props.value_percentage}

                value_Fertigation_Tank_Board={this.props.value_Fertigation_Tank_Board}
                value_Fertigation_Tank_Additional_Board={this.props.value_Fertigation_Tank_Additional_Board}
                value_Fertigation_Stand={this.props.value_Fertigation_Stand}
                value_Fertigation_Pump={this.props.value_Fertigation_Pump}
                value_Fertigation_Valves={this.props.value_Fertigation_Valves}
                value_NRV={this.props.value_NRV}
                value_Float={this.props.value_Float}

                list_item_valve_sensor={this.props.list_item_valve_sensor}
                list_item_ws={this.props.list_item_ws}
                list_item_cs={this.props.list_item_cs}
                list_item_pm={this.props.list_item_pm}
                header={this.props.header}

                item_id={null}

                onUpdateIsAddMode={this.onUpdateIsAddMode}
                onUpdateIsEditMode={this.onUpdateIsEditMode}
                heading="Add Item"
              />


            </div>
          )
        }
        <CssBaseline />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ItemList);