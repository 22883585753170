import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
//import ListItemText from '@material-ui/core/ListItemText';
//import ListItem from '@material-ui/core/ListItem';
//import List from '@material-ui/core/List';
//import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ItemManagement from './ItemManagement.js';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function showPage(props, handleClose) {
  return (
    <ItemManagement
      item_details={props.item_details}
      IsItemLoaded={props.IsItemLoaded}


      header={props.header}
      list_item_flow_details={props.list_item_flow_details}
      list_item_backwash_details={props.list_item_backwash_details}
      list_item_valve_size={props.list_item_valve_size}
      list_item_valve_type={props.list_item_valve_type}

      list_item_valve_sensor={props.list_item_valve_sensor}
      list_item_ws={props.list_item_ws}
      list_item_cs={props.list_item_cs}
      list_item_pm={props.list_item_pm}
      valve_price_details={props.valve_price_details}
      value_percentage={props.value_percentage}
      value_Fertigation_Tank_Board={props.value_Fertigation_Tank_Board}
      value_Fertigation_Tank_Additional_Board={props.value_Fertigation_Tank_Additional_Board}
      value_Fertigation_Stand={props.value_Fertigation_Stand}
      value_Fertigation_Pump={props.value_Fertigation_Pump}
      value_Fertigation_Valves={props.value_Fertigation_Valves}
      value_NRV={props.value_NRV}
      value_Float={props.value_Float}
      item_id={props.item_id}

      onUpdateIsAddMode={props.onUpdateIsAddMode}
      onUpdateIsEditMode={props.onUpdateIsEditMode}
      onUpdateItemDetails={props.onUpdateItemDetails}

      heading={props.heading}
      handleClose={handleClose}
    />
  );


}

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    props.onUpdateIsAddMode(false);
    props.onUpdateIsEditMode();
  };


  return (
    <div>

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.heading}
            </Typography>
          </Toolbar>
        </AppBar>
        {showPage(props, handleClose)}
      </Dialog>
    </div>
  );
}
