import React from 'react';
import ReactDOM from 'react-dom';


import Shield from './Shield.js';
import Home from './home/Home.js';
function App(){
	return (
		
			<Shield>
				<Home />
			</Shield>
		
	);
}

ReactDOM.render(<App />, document.getElementById('root'));

export default (App)